import { isEmail } from '../handlers/misc';
import AnalyticsHandler from "../handlers/AnalyticsHandler.js";

/**
 * NotAccountActivationPage class.
 */
export default class NotAccountActivationPage {

    /**
     * NotAccountActivationPage constructor.
     */
    constructor () {
        this.$resendAccountActivation = $('#js-resend-account-activation');
        this.$resendAccountActivationForm = this.$resendAccountActivation.find('form').first();
        this.$emailField = this.$resendAccountActivationForm.find('input[type="email"]').first();
        this.$errorMsg = $('.js-email-error');
        this.$activateAccount = $('#js-activate-account');
        this.$expiredToken = $('#js-expired-token');
        this.isEmailValidated = false;
        this.analyticsHandler = new AnalyticsHandler();
    }

    /**
     * Execute component.
     */
    execute() {
        this.$activateAccount.on('click', () => {
            this.onClickActivateAccount();
        });

        this.$resendAccountActivation.on('click', () => {
            this.analyticsHandler.sendGUIButtonCLickEvent('activate_account');
        });
    }

    /**
     * On click activate account.
     */
    onClickActivateAccount() {
        this.showReSendAccountActivationForm();
        this.onSubmitReSendAccountActivationForm();
        this.onEmailFieldFocus();
    }

    /**
     * Show resend account activation form
     */
    showReSendAccountActivationForm() {
        this.$expiredToken.fadeOut(500, () => {
            this.$resendAccountActivation.fadeIn(500, () => {
                $('#yeeply_resend_user_account_activation_email').focus();
            });
        });
    }

    /**
     * On submit resend account activation form.
     */
    onSubmitReSendAccountActivationForm() {
        this.$resendAccountActivationForm.on('submit', (event) => {
            if (!this.isEmailValidated) {
                event.preventDefault();

                let email = this.$emailField.val();
                if (isEmail(email)) {
                    this.isEmailValidated = true;
                    this.$resendAccountActivationForm.submit();
                } else {
                    this.showEmailError();
                }
            }
        });
    }

    /**
     * Show email error.
     */
    showEmailError() {
        this.$errorMsg.addClass("fadeIn");
        this.$emailField.addClass("danger");
        this.$resendAccountActivationForm.find('.form-group').addClass('has-error');
    }

    /**
     * Hide email error.
     */
    hideEmailError() {
        this.$errorMsg.removeClass("fadeIn");
        this.$emailField.removeClass("danger");
        this.$resendAccountActivationForm.find('.form-group').removeClass('has-error');
    }

    /**
     * On email field focus.
     */
    onEmailFieldFocus() {
        this.$emailField.on('focus, click', () => {
            this.hideEmailError();
        });
    }
}