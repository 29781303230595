import YeeplyTabsVueComponent from './YeeplyTabsVueComponent';
import YeeplyTabVueComponent from './YeeplyTabVueComponent';
import VueCollapse from 'vue2-collapse';

Vue.use(VueCollapse)

/**
 * YeeplyTabsVueComponent class.
 */
export default class YeeplyTabsVue extends Vue {
    /**
     * InformationProjectRequestVueComponent constructor.
     *
     */
    constructor() {
        super({
            el: '.yeeply-tabs',
            components: {
                'yeeply-tab' : YeeplyTabVueComponent,
                'yeeply-tabs' : YeeplyTabsVueComponent,                  
            },
            data () {
                return {
                  form: [
                    { classActive: true },
                    { classActive: false }
                  ]
                }
              },
            methods: {
                activateClass: function(refName, index){                    
                    this.form.map(item => item.classActive = false);
                    this.form[index].classActive = !this.$refs[refName].status
                }
            }
        });
    }
}
