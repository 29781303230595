/**
 * LandingModalFormVersionPage class.
 */
export default class LandingModalFormVersionPage {

    /**
     * Execute.
     */
    execute() {
        this.initSlider();
    }

    initSlider() {
        $('.js-logos-slider').slick({
            arrows: false,
            dots: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            adaptiveHeight: false,
            responsive: [
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                }
            ]
        });
    }
}