import PublishProjectLandingVueComponent from './PublishProjectLandingVueComponent';

/**
 * PublishProjectLandingVue class.
 */
export default class PublishProjectLandingVue extends Vue {
    /**
     * InformationProjectRequestVueComponent constructor.
     *
     */
    constructor() {
        super({
            el: '.brand-form',
            components: {
                'publish-project-landing' : PublishProjectLandingVueComponent
            }
        });
    }
}