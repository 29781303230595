import SectionEventsCalculatorHandler from "../handlers/SectionEventsCalculatorHandler";

/**
 *
 */
export default class HomePage {

    /**
     * HomePage constructor.
     */
    constructor() {
        this.sectionEventsCalculator = new SectionEventsCalculatorHandler('/home');
    }

    /**
     * Execute.
     */
    execute() {
        let self = this;
        $(window).scroll(function() {
            self.sectionEventsCalculator.calculateToSendEvent();
        });
    }
}