/**
 * YeeplyTabsVueComponent Vue component.
 * Based on https://github.com/spatie/vue-tabs-component
 */


const YeeplyTabsVueComponent = Vue.component('yeeply-tabs', {
    template: '#yeeply-tabs-landing-template',
    props: {
        cacheLifetime: {
            default: 5,
        },
        options: {
            type: Object,
            required: false,
            default: () => ({
                useUrlFragment: false,
            }),
        },        
    },
    data: () => ({
            tabs: [],
            activeTabHash: '',
    }),
    computed: {
      storageKey() {
          return `vue-tabs-component.cache.${window.location.host}${window.location.pathname}`;
      },
  },
    created: function() {
        this.tabs = this.$children;
    },
    mounted: function() {
        window.postMessage({component:'yeeply-tabs', hook:'mounted'}, "*");

        window.addEventListener('hashchange', () => this.selectTab(window.location.hash));
        if (this.findTab(window.location.hash)) {
            this.selectTab(window.location.hash);
            return;
        }
        const previousSelectedTabHash = this.getExpiringStorage(this.storageKey);
        if (this.findTab(previousSelectedTabHash)) {
            this.selectTab(previousSelectedTabHash);
            return;
        }
        if (this.tabs.length) {
            this.selectTab(this.tabs[0].hash);
        }

    },
    methods: {
            findTab(hash) {
                return this.tabs.find(tab => tab.hash === hash);
            },
            selectTab(selectedTabHash, event) {
                // See if we should store the hash in the url fragment.
                if (event && !this.options.useUrlFragment) {
                    event.preventDefault();
                }
                const selectedTab = this.findTab(selectedTabHash);
                if (! selectedTab) {
                    return;
                }
                if (selectedTab.isDisabled) {
                    return;
                }
                this.tabs.forEach(tab => {
                    tab.isActive = (tab.hash === selectedTab.hash);
                });
                this.$emit('changed', { tab: selectedTab });
                this.activeTabHash = selectedTab.hash;
                this.setExpiringStorage(this.storageKey, selectedTab.hash, this.cacheLifetime);
            },
            setTabVisible(hash, visible) {
                const tab = this.findTab(hash);
                if (! tab) {
                    return;
                }
                tab.isVisible = visible;
                if (tab.isActive) {
                    // If tab is active, set a different one as active.
                    tab.isActive = visible;
                    this.tabs.every((tab, index, array) => {
                        if (tab.isVisible) {
                            tab.isActive = true;
                            return false;
                        }
                        return true;
                    });
                }
            },
            getExpiringStorage(key) {
                const cached = JSON.parse(
                    localStorage.getItem(key)
                );

                if (! cached)
                    return null;
                
                const expires = new Date(cached.expires);

                if (expires < new Date()) {
                    localStorage.removeItem(key);
                    return null;
                }

                return cached.value;
            },
            setExpiringStorage(key, value, lifeTimeInMinutes) {
                const currentTime = new Date().getTime();

                const expires = new Date(currentTime + lifeTimeInMinutes * 60000);

                localStorage.setItem(key, JSON.stringify({ value, expires }));
            }
            
    },
});

export default YeeplyTabsVueComponent;
    
    