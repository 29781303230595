import CookiesHandler from "./CookiesHandler";
import UUIDHandler from "./UUIDHandler";
import AnalyticsHandler from "./AnalyticsHandler";

/**
 * FirstClickHandler
 */
export default class FirstClickHandler {

    /**
     * FirstClickHandler constructor.
     */
    constructor () {
        this.cookieHandlerComponent = new CookiesHandler();
        this.uuidHandler = new UUIDHandler();
        this.analyticsHandler = new AnalyticsHandler();
    }

    execute() {
        this.createFirstClickCookie();
    }

    createFirstClickCookie() {

        let uuid = this.cookieHandlerComponent.getSessionCookie();

        if ('undefined' === typeof uuid) {
            uuid = this.uuidHandler.generateV4();
        }

        this.analyticsHandler.sendCustomDimensionWithoutPageName('dimension17', uuid);
        this.cookieHandlerComponent.addFirstClickCookie(uuid);
        this.cookieHandlerComponent.addFirstClickSessionCookie(uuid);
    }
}