import './config/vue-environment';
import CommonPage from "./pages/CommonPage";
import WhoWeArePage from "./pages/WhoWeArePage.js";
import CreateAppPage from "./pages/CreateAppPage.js";
import CreateWebPage from "./pages/CreateWebPage.js";
import NotAccountActivationPage from "./pages/NotAccountActivationPage";
import AccountActivationPage from "./pages/AccountActivationPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import NotResetPasswordPage from "./pages/NotResetPasswordPage";
import HomePage from "./pages/HomePage";
import LandingFirstVersionPage from "./pages/LandingFirstVersionPage";
import HowItWorksPage from "./pages/HowItWorksPage";
import LandingModalFormVersionPage from "./pages/LandingModalFormVersionPage";

$(document).ready(function () {
    'use strict';

    //Common Code for all pages
    new CommonPage().execute();

    if ('undefined' === typeof jsPageName) {
        return false;
    }

    if ('home' === jsPageName ) {
        new HomePage().execute();
        return;
    }

    if ('who-we-are' === jsPageName ) {
        new WhoWeArePage().execute();
        return;
    }

    if ('how-to-create-an-app' === jsPageName ) {
        new CreateAppPage().execute();
        return;
    }

    if ('create-a-website' === jsPageName ) {
        new CreateWebPage().execute();
        return;
    }

    if ('account-activation' === jsPageName) {
        new AccountActivationPage().execute();
        return;
    }

    if ('not-account-activation' === jsPageName ) {
        new NotAccountActivationPage().execute();
        return;
    }

    if ('reset-password' === jsPageName) {
        new ResetPasswordPage().execute();
        return;
    }

    if ('not-reset-password' === jsPageName ) {
        new NotResetPasswordPage().execute();
        return;
    }

    if ('landing-first-version' === jsPageName ) {
        new LandingFirstVersionPage().execute();
        return;
    }

    if ('landing-modal-form-version' === jsPageName ) {
        new LandingModalFormVersionPage().execute();
        return;
    }

    if ('how-it-works' === jsPageName ) {
        new HowItWorksPage().execute();
        return;
    }

});