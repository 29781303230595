
/**
 * SessionStorageHandler
 */
export default class SessionStorageHandler {

    constructor() {
        if (typeof(Storage) === "undefined") {
          console.error("NO PODEMOS UTILIZAR SESSION STORAGE");
        }
    }

    getPageViews() {
        return sessionStorage.getItem('yp-pv');
    }

    sendPageView() {
        this.pageViews = JSON.parse(sessionStorage.getItem('yp-pv'));
        this.pageViews = (this.pageViews) ? this.pageViews + 1 : 1;

        sessionStorage.setItem('yp-pv', JSON.stringify(this.pageViews));
    }
}
