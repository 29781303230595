/**
 * CookiesHandler
 */
import GetDocumentDomainUtil from "../utils/GetDocumentDomainUtil";
import AnalyticsHandler from "./AnalyticsHandler";
import SessionStorageHandler from "./SessionStorageHandler";

const FIRST_CLICK = 'firstClick';
const FIRST_CLICK_SESSION = 'firstClickSession';

export default class CookiesHandler {


    /**
     * CookiesHandler constructor.
     */
    constructor () {
        this.analyticsHandler = new AnalyticsHandler();
    }

    /**
     * @param countryCode
     * @returns {*}
     */
    addSessionCountryCode(countryCode) {
        return Cookies.set(
            'sessionCountryCode',
            countryCode,
            { domain: GetDocumentDomainUtil.getDocumentDomain() }
        );
    }

    /**
     * @returns {*}
     */
    getSessionCountryCode() {
        return Cookies.get('sessionCountryCode');
    }

    /**
     * @returns {*}
     */
    addFirstClickCookie(uuid) {

        let firstClickCookie = Cookies.get(FIRST_CLICK);
        if ('undefined' !== typeof firstClickCookie) {
            uuid = firstClickCookie;
        }

        return Cookies.set(FIRST_CLICK, uuid, {
            domain: GetDocumentDomainUtil.getDocumentDomain(),
            expires: 30,
        });
    }

    /**
     * @returns {*}
     */
    addFirstClickSessionCookie(uuid) {

        let firstClickCookieSession = Cookies.get(FIRST_CLICK_SESSION);
        if ('undefined' !== typeof firstClickCookieSession) {
            uuid = firstClickCookieSession;
        }

        return Cookies.set(FIRST_CLICK_SESSION, uuid, {
            domain: GetDocumentDomainUtil.getDocumentDomain(),
            expires: new Date(new Date().getTime() + 30 * 60 * 1000),
        });
    }

    /**
     * @returns {*}
     */
    getSessionCookie() {
        return Cookies.get(FIRST_CLICK_SESSION);
    }
}