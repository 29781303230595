import SectionEventsCalculatorHandler from "../handlers/SectionEventsCalculatorHandler.js";

/**
 * CreateWebPage class.
 */
export default class CreateWebPage {
    /**
     * CreateWebPage constructor.
     */
    constructor() {
        this.sectionEventsCalculator = new SectionEventsCalculatorHandler('/create-a-website');
    }

    /**
     * Execute.
     */
    execute() {
        let self = this;
        $(window).scroll(function() {
            self.sectionEventsCalculator.calculateToSendEvent();
        });
    }
}