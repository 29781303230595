import PublishProjectLandingVue from './vue/PublishProjectLandingVue';

/**
 * PublishProjectLandingComponent class.
 */
export default class PublishProjectLandingComponent {
    /**
     * Execute component.
     */
    execute() {
        this.initComponent();
    }

    /**
     * Init Vue Component
     */
    initComponent() {
        new PublishProjectLandingVue();
    }
}