import AnalyticsHandler from "./AnalyticsHandler.js";

/**
 * SectionEventsCalculatorHandler class.
 */
export default class SectionEventsCalculatorHandler {

    /**
     * SectionEventsCalculatorHandler constructor.
     *
     * @param {String} lastEventSend - Last event send
     */
    constructor(lastEventSend) {
        this.sectionsStarts = [];
        this.sectionsEnds = [];
        this.top = 0;
        this.bottom = 0;
        this.nextEventsDown = [];
        this.nextEventsUp = [];
        this.lastScroll = 0;
        this.viewportMidline = $(window).height() / 2;
        this.lastEventSend = lastEventSend;
        this.analyticsHandler = new AnalyticsHandler();
    }

    /**
     * Calculate to send event
     */
    calculateToSendEvent() {
        this.reCalculateSections();

        let scroll = $(window).scrollTop() + this.viewportMidline;

        if (scroll > this.lastScroll) {
            this.sendEventOnScrollDown(scroll);
        } else {
            this.sendEventOnScrollUp(scroll);
        }

        this.lastScroll = scroll;
    }

    /**
     * Recalculate sections.
     */
    reCalculateSections() {
        let self = this;

        this.restartSections();
        $('.js-section-vp-calculators').each(function() {
            self.calculateSectionsReferences($(this), $(this).data('anvp'));
        });
    }

    /**
     * Restart Sections.
     */
    restartSections() {
        this.sectionsStarts = [];
        this.sectionsEnds = [];
    }

    /**
     * Calculate section references.
     *
     * @param {Jquery} section - Jquery section element
     * @param {String} event   - Event
     */
    calculateSectionsReferences(section, event) {
        this.top = section.offset().top;
        this.bottom = this.top + section.outerHeight();

        this.addValueToSectionsStarts(this.top, event);
        this.addValueToSectionsEnds(this.bottom, event);
    }

    /**
     * Add value to section starts.
     *
     * @param {int}    top   - Top position
     * @param {String} event - Event
     */
    addValueToSectionsStarts(top, event) {
        this.sectionsStarts.push({
            'breakpoint': top,
            'event': event
        });
    }

    /**
     * Add value to section ends.
     *
     * @param {int}    top   - Top position
     * @param {String} event - Event
     */
    addValueToSectionsEnds(top, event) {
        this.sectionsEnds.push({
            'breakpoint': top,
            'event': event
        });
    }

    /**
     * Send events on scroll down.
     *
     * @param {int} scroll - Scroll position
     */
    sendEventOnScrollDown(scroll) {
        if (this.nextEventsDown.length > 0 ) {
            let breakpoint = this.nextEventsDown[0]['breakpoint'],
                event = this.nextEventsDown[0]['event'];

            if (scroll > breakpoint) {
                if (this.lastEventSend !== event) {
                    this.analyticsHandler.sendVirtualPageEvent(event);
                    this.lastEventSend = event;
                }
            }
        }

        this.nextEventsDown = this.sectionsStarts.filter(function(scrollValues) {
            return scrollValues['breakpoint'] > scroll;
        });
    }

    /**
     * Send events on scroll up.
     *
     * @param {int} scroll - Scroll position
     */
    sendEventOnScrollUp(scroll) {
        if (this.nextEventsUp.length > 0 ) {
            let breakpoint = this.nextEventsUp[this.nextEventsUp.length - 1]['breakpoint'],
                event = this.nextEventsUp[this.nextEventsUp.length - 1]['event'];

            if (scroll < breakpoint) {
                if (this.lastEventSend !== event) {
                    this.analyticsHandler.sendVirtualPageEvent(event);
                    this.lastEventSend = event;
                }
            }
        }

        this.nextEventsUp = this.sectionsEnds.filter(function(scrollValues) {
            return scrollValues['breakpoint'] < scroll;
        });
    }
}
