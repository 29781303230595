/**
 * YeeplyModalComonent class.
 */
export default class YeeplyModalComponent {
    /**
     * Execute component.
     */
    execute() {
        this.checkUrlHash();
    }

    checkUrlHash() {
        if (location.hash.includes('#publish-project')) {
            var _this = this;
            window.addEventListener('ypWebComponentLoaded', function(event) {
                _this.openModal()
            });
        }
    }

    openModal() {
        let evt = new CustomEvent('ypOpenPublishProjectModalEvent');
        window.dispatchEvent(evt);
    }
}
