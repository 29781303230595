/**
 * AnalyticsHandler class.
 */
import SessionStorageHandler from "./SessionStorageHandler";

export default class AnalyticsHandler {

    /**
     * CommonPage constructor.
     */
    constructor () {
        this.sessionStorage = new SessionStorageHandler();
    }

    /**
     * @param {String} dimension
     * @param {String} value
     */
    sendCustomDimensionWithoutPageName(dimension, value) {
        ga('set', dimension, value);
        // ga('send', 'pageview');
    }

    /**
     * @param {String} event - Event
     */
    sendVirtualPageEvent(event) {
        this.sessionStorage.sendPageView();

        ga('set', 'page', event);
        ga('send', 'pageview');
    }

    /**
     * @param label
     */
    sendGUIButtonCLickEvent(label) {
        ga('send', 'event', 'GUI', 'Button_Click', label);
    }

    /**
     * @param label
     */
    sendGUIButtonErrorEvent(label) {
        ga('send', 'event', 'GUI', 'Button_Error', label);
    }

    /**
     * @param label
     * @param value
     */
    sendGUIButtonErrorEventWithValue(label, eventValue) {
        ga('send', 'event', 'GUI', 'Button_Error', label, eventValue);
    }

    /**
     * @param label
     * @param pageName
     */
    sendGUIButtonCLickEventWithCustomPageName(label, pageName) {
        ga('set', 'page', pageName);
        ga('send', 'event', 'GUI', 'Button_Click', label);
    }

    /**
     * @param pageName
     * @param eventCategory
     * @param eventAction
     * @param eventLabel
     * @param eventValue
     */
    sendEventWithPage(pageName, eventCategory, eventAction, eventLabel, eventValue) {
        ga('set', 'page', pageName);
        ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue);
    }

    getTracker() {
        let analyticsTracker = null;
        ga(function (tracker) {
            analyticsTracker = tracker;
        });

        return analyticsTracker;
    }

    /**
     * @param label
     */
    sendDeveloperProjectEvent(label) {
        ga('send', 'event', 'Developer', 'Project', label);
    }
};