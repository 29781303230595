import YeeplyTabsVue from './vue/yeeplyTabs/YeeplyTabsVue';

/**
 * YeeplyTabsComponent class.
 */
export default class YeeplyTabsComponent {
    /**
     * Execute component.
     */
    execute() {
        this.initComponent();
    }

    /**
     * Init Vue Component
     */
    initComponent() {
        new YeeplyTabsVue();
    }
}