/**
 * PublishProjectLandingVueComponent Vue component.
 */
import CookiesHandler from "../../handlers/CookiesHandler";

const VP_ANALYTICS_CATEGORY = '/publish-project/vp/step1';
const VP_ANALYTICS_EMAIL = '/publish-project/vp/step2';
const VP_ANALYTICS_PHONE = '/publish-project/vp/step3';

const PublishProjectLandingVueComponent = Vue.component('publish-project-landing', {
    template: '#yp-publish-project-landing-template',
    data() {
        return {
            projectCategory: '',
            email: '',
            phone: '',
            isSendingRequest: false,
            errors: {
                projectCategory: false,
                email: false,
                phone: false,
            },
            validations: {
                projectCategory: false,
                email: false,
                phone: false
            },
            requestResponseMessage: ''
        }
    },
    mounted: function() {
        this.initForm();
        this.initCookiesHandler();
    },
    methods: {
        initForm: function () {
            this.focusOnProjectCategory();
            this.initPhoneFormField();
        },
        initCookiesHandler: function () {
            this.cookiesHandler = new CookiesHandler();
        },
        focusOnProjectCategory: function () {
            $(this.$refs.selectProjectCategory).find('.selectpicker').on('loaded.bs.select', function (e) {
                $("button[data-id='project-category']").first().focus();
            });
        },
        initPhoneFormField: function() {
            let phoneNumber = $('#js-phone-number-intl');
            let jsPhoneNumber = $('.js-phone-number');

            if (phoneNumber.length > 0) {
                phoneNumber.intlTelInput({
                    initialCountry: app.intlTelInput.initialCountry,
                    preferredCountries: ['es', 'gb', 'fr', 'it', 'de','pt'],
                    utilsScript: '/dist/js/int-tel-input-utils.js',
                    customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                        return "e.g. " + selectedCountryPlaceholder;
                    }
                }).done(function () {
                    $(".flag-container").find(".selected-flag").attr("tabindex", "-1");
                });
            }
        },
        updatePhone: function(event) {
            this.phone = $(event.target).intlTelInput("getNumber");
        },
        checkForm: function(ev) {
            ev.preventDefault();

            if (!this.formHasErrors()) {
                this.submitForm();
            }
        },
        formHasErrors: function () {
            this.validateForm();

            if (Object.values(this.errors).indexOf(true) > -1) {
                return true;
            }

            return false;
        },
        validateForm: function() {
            this.errors.projectCategory = !this.isValidProjectCategory();
            this.errors.email = !this.isValidEmail();
            this.errors.phone = !this.isValidPhone();
        },
        isValidProjectCategory: function() {
            return '' != this.projectCategory;
        },
        isValidEmail: function() {
            let expr = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return expr.test(this.email.toLowerCase());
        },
        isValidPhone: function() {
            return $(this.$refs.phoneNumberIntl).intlTelInput('isValidNumber');
        },
        submitForm: function () {
            let data = {
                projectCategory: this.projectCategory,
                email: this.email,
                phone: this.phone,
                country_code: this.cookiesHandler.getSessionCountryCode()
            };

            $.ajax({
                type: 'POST',
                url: Routing.generate('publish_project_landing'),
                data: data,
                dataType: 'json',
                beforeSend: () => {
                    this.isSendingRequest = true;
                },
                success: (response, status, xhr) => {
                    ga('send', 'event', 'Form_Budget/contract project', 'Success', 'Budget', 1);
                    this.requestResponseMessage = '';
                    window.location = response.data;
                },
                error: (response, status) => {
                    this.requestResponseMessage = response.responseJSON.feedback[0].message;
                    this.isSendingRequest = false;
                }
            });
        },
        categoryChanged: function () {
            let isValidProjectCategory = this.isValidProjectCategory();
            this.validations.projectCategory = isValidProjectCategory;

            if (isValidProjectCategory) {
                this.errors.projectCategory = false;
                this.removeDefaultCategoryOption();
                this.sendDataToAnalytics(VP_ANALYTICS_CATEGORY);
            }
        },
        removeDefaultCategoryOption: function () {
            $(this.$refs.selectProjectCategory).find('select option[value=""]').remove();
            $(this.$refs.selectProjectCategory).find('select').selectpicker('refresh');
        },
        emailChanged: function () {
            let isValidEmail = this.isValidEmail();
            this.validations.email = isValidEmail;

            if (isValidEmail) {
                this.errors.email = false;
                this.sendDataToAnalytics(VP_ANALYTICS_EMAIL);
            }
        },
        phoneChanged: function () {
            let isvalidPhone = this.isValidPhone();
            this.validations.phone = isvalidPhone;

            if (isvalidPhone) {
                $(event.target).intlTelInput("setNumber", this.phone);
                this.errors.phone = false;
                this.sendDataToAnalytics(VP_ANALYTICS_PHONE);
            }
        },
        sendDataToAnalytics: function(pageName, customDimensionValue) {
            var dataToAnalytics = {page: pageName};

            if (customDimensionValue !== undefined) {
                dataToAnalytics['dimension7'] = customDimensionValue;
            }

            ga('send', 'pageview', dataToAnalytics);
        }
    },
});

export default PublishProjectLandingVueComponent;