import YeeplyTabsComponent from "../components/YeeplyTabsComponent.js";

/**
 * LandingFirstVersionPage class.
 */
export default class HowItWorksPage {
    /**
     * Execute.
     */
    execute() {
        this.initPublishProjectLandingComponent();
    }

  
    initPublishProjectLandingComponent() {
        let yeeplyTabsComponent = new YeeplyTabsComponent();
        yeeplyTabsComponent.execute();
    }
}