import YeeplyModal from "../components/YeeplyModalComponent.js";
import SessionStorageHandler from "../handlers/SessionStorageHandler";
import IdentifySessionCountryComponent from '../components/IdentifySessionCountryComponent';
import FirstClickHandler from "../handlers/FirstClickHandler";

/**
 * CommonPage class.
 */
export default class CommonPage {

    /**
     * CommonPage constructor.
     */
    constructor () {
        this.yeeplyModal = new YeeplyModal();
        this.sessionStorage = new SessionStorageHandler();
        this.identifySessionCountryComponent = new IdentifySessionCountryComponent();
        this.firstClickHandler = new FirstClickHandler();
    }

    /**
     * Execute component.
     */
    execute() {
        this.yeeplyModal.execute();
        this.sendPageView();
        this.identifySessionCountryComponent.execute();
        this.firstClickHandler.execute();
    }

    /**
     * Send page view to session storage
     */
    sendPageView() {
        this.sessionStorage.sendPageView();
    }
}
