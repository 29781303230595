import AnalyticsHandler from "../handlers/AnalyticsHandler";
import CookiesHandler from "../handlers/CookiesHandler";

/**
 * IdentifySessionCountryComponent class.
 */
export default class IdentifySessionCountryComponent {

    constructor() {
        this.analyticsHandler = new AnalyticsHandler();
        this.cookiesHandler = new CookiesHandler();
    }

    /**
     * Execute component.
     */
    execute() {
        this.initComponent();
    }

    /**
     * Init Component
     */
    initComponent() {
        if (!this.cookiesHandler.getSessionCountryCode()) {
            this.getSessionCountry().done((countryCode) => {
                this.cookiesHandler.addSessionCountryCode(countryCode);
                this.analyticsHandler.sendCustomDimensionWithoutPageName(
                    'dimension16',
                    countryCode
                );
            });
        }
    }

    getSessionCountry() {
        var url = Routing.generate('calculate_session_country_code');

        var deferred = $.Deferred();
        $.get(url)
            .success(function(data) {
                deferred.resolve(data);
            }).error(function() {
            deferred.reject();
            console.log("Error getting session country code");
        });

        return deferred;
    }
}
