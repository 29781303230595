/**
 * AccountActivation class.
 */
export default class ResetPasswordPage {
    /**
     * AccountActivation constructor.
     */
    constructor () {
        this.$passwordField = $('#yeeply_user_reset_password_password');
        this.$activationPasswordForm = $('form[name="yeeply_user_reset_password"]').first();
        this.isPasswordValidated = false;
        this.$errorMsg = $('.js-password-error');
    }

    /**
     * Execute component.
     */
    execute() {
        this.initHideShowPassword();
        this.onSubmitActivationPasswordForm();
        this.onPasswordFieldFocus()
    }

    /**
     * Init hide show password jquery plugin.
     */
    initHideShowPassword(){
        setTimeout(function(){
            $("#yeeply_user_reset_password_password").hideShowPassword(true, 'focus', {
                show: false
            }).focus();

        }, 100);

    }

    /**
     * On submit password form.
     */
    onSubmitActivationPasswordForm() {
        this.$activationPasswordForm.on('submit', (event) => {
            if (!this.isPasswordValidated) {
                event.preventDefault();

                let password = this.$passwordField.val();
                if (null !== password && "" !== password.trim()) {
                    this.isPasswordValidated = true;
                    this.$activationPasswordForm.submit();
                } else {
                    this.showPasswordError();
                }
            }
        });
    }

    /**
     * Show password error.
     */
    showPasswordError() {
        this.$errorMsg.addClass("fadeIn");
        this.$passwordField.addClass("danger");
        this.$activationPasswordForm.find('.form-group').addClass('has-error');
    }

    /**
     * Hide password error.
     */
    hidePasswordError() {
        this.$errorMsg.removeClass("fadeIn");
        this.$passwordField.removeClass("danger");
        this.$activationPasswordForm.find('.form-group').removeClass('has-error');
    }

    /**
     * On password field focus.
     */
    onPasswordFieldFocus() {
        this.$passwordField.on('focus, click', () => {
            this.hidePasswordError();
        });
    }
}